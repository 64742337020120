<template>
 <div>
    <!-- <p>
      <router-link to="/send">/send 发起流程</router-link>
    </p>
    <p>
      <router-link to="/myRequest">/myRequest 我的请求</router-link>
    </p>
    <p>
      <router-link to="/todo">/todo 待办列表</router-link>
    </p>
    <p>
      <router-link to="/history">/history 历史提交</router-link>
    </p>
    <p>
      <router-link to="/doDone">/doDone 我的已办</router-link>
    </p>
    <p>
      <router-link to="/flowMonitoring">/flowMonitoring 流程监控</router-link>
    </p>
    <p>
      <router-link to="/msgNotify">/msgNotify 消息通知</router-link>
    </p>
    <p>
      <router-link to="/log">/log 我的日志</router-link>
    </p>
    <p>
      <router-link to="/empLog">/empLog 员工日志</router-link>
    </p> -->
  </div>
</template>

<script>
export default {
  created() {
    console.log("routes ->", this.$router.options.routes);
  },
  components: {},
};
</script>

<style lang="less" scoped></style>
